.control-overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  /* background: pink; */
  background: transparent;
  z-index: 2;
}
