/* @import url(https://fonts.googleapis.com/css?family=Roboto+Condensed:400&subset=latin,latin-ext);
@import url(https://fonts.googleapis.com/css?family=Poppins); */

:root {
  --brand-color-font: #f2b805;
  --brand-color-foreRGBA: rgba(242, 184, 5, 0.75);
  /* --brand-color-bg: #4472c4; */

  --brand-color-bg: #25274d;
  --max-width: 60rem;
  --bp-small: 48em;
  --bp-medium: 64em;
  --bp-large: 85.375em;
  --bp-xlarge: 120em;
  --bp-xxlarge: 160em;

--color01: #25274d;
--color02: #464866;
--color03: #aaabb8;
--color04: #2e9cca;
--color05: #29648a;

--primary-font-color: #2e9cca;
--border-rad: 4px;
}

@font-face {
  font-family: "Roboto";
  src: local("Roboto-Medium"),
  url("./fonts/Roboto-Medium.ttf") format("truetype");
}

.App {
  display: flex;
  text-align: center;
  background:
        linear-gradient(to right bottom, rgba(79, 75, 81, .2) 0%, rgba(79, 75, 81, 0.5) 10% /*middle color*/, rgba(79, 75, 81, 1) 100% /*top-left color*/),
        linear-gradient(rgba(0, 0, 0, 1), rgba(0, 0, 0, 1));
  color: var(--color04);
  font-family: "Poppins";
  font-family: "Roboto";
  width: 100%;
  padding: 0;
  margin: 0;

  height: 100vh;
  overflow-y: scroll;
  overflow: hidden;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
  
}

html {
  font-size: 50%;
  overflow: hidden;
}

@media only screen and (min-width: 360px) and (min-height: 740px) {
  html {
    font-size: 25%;
  }
}

@media only screen and ((min-height: 200px)) {
  html {
    font-size: 25%;
  }
}

@media only screen and ((min-height: 400px)) {
  html {
    font-size: 37.5%;
  }
}

@media only screen and ((min-height: 620px)) {
  html {
    font-size: 62.5%;
  }
}

@media only screen and ((min-height: 1000px)) {
  html {
    font-size: 100%;
  }
}


@media only screen and ((min-height: 1200px)) {
  html {
    font-size: 125%;
  }
}


@media only screen and ((min-height: 1500px)) {
  html {
    font-size: 150%;
  }
}


@media only screen and ((min-height: 1900px)) {
  html {
    font-size: 175%;
  }
}

.App-logo {
  /* height: 40vmin; */
  pointer-events: none;
}

.marg-auto {
  margin: auto;
}

.App-header {
  /* background-color: #282c34; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}


.workspace {
  /* margin: auto; */
  height: 100%;
  height: 100vh;
}
.outer-cont {
  height: 100%;
  width: 100%;
  width: 100vw;
  margin: auto;
}
.page-container {
  /* height: 100vh;
  width: 100vw; */
  position: relative;
  max-width: var(--max-width);
  margin: auto;
  height: 100%;
  width: 90%;
}

.kmt-logo {
 
  /* width: 100%; */
  right: 0;
  padding: 1rem;
  max-height: 16rem;
  height: 8rem;
}

.page-header-row {
  grid-template-columns: repeat(1, minmax(0, 1fr));
  /* height: 11rem; */
  display: grid;
  overflow: hidden;
  align-items: center;
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 0;
  padding: .5rem;
  
}

.hdr-acronym {
  display: flex;
  align-items: center;
  width: 90vw;
  margin: auto;
  height: 6rem;
  border-radius: var(--border-rad);
  font-size: 1.5rem;
  color: #fff;

  margin-bottom: 1rem;
  background: linear-gradient(to left top, rgba(11, 146, 221, .2) 0%, rgba(11, 146, 221, .5) 25%,  rgba(11, 146, 221, 1) 100% /*top-left color*/);
  box-shadow: .2rem .2rem .2rem .1rem rgba(0, 0, 0, 0.2);
  padding: 1rem;
  width: 100%;

}



.mw-row {
  max-width: var(--max-width);
  margin: auto;
  width: 100%;
}

.control-row {
  margin: auto;
  display: grid;
  grid-template-columns: 12fr;
  padding: 1rem 0rem 1rem 0rem;
}

.numeric-slider {
  position: relative;
  margin: auto;
  height: 2.5rem;
  background: white;
  border-radius: var(--border-rad);
  color: #000;
  transition: height 0.2s ease-in-out;
  box-shadow: .2rem .2rem .2rem .1rem rgba(0, 0, 0, 0.2);
  padding-top: 0 !important;
  
}
.slider-row-grid {
  display: grid;
  grid-template-columns: minmax(0, 1.25fr) 8fr 1fr;
  height: 100%;
  padding: 0;
  overflow: hidden;
}
.ctl-num-lbl {
  height: 100%;
  border-radius: var(--border-rad) 0 0 var(--border-rad);
  background: linear-gradient(to left bottom, rgba(11, 146, 221, .7) 0%,  rgba(11, 146, 221, 1) 100% /*top-left color*/);
  color: white;
  display: flex;
  align-items: center;
  font-size: larger;
}

.slider-row-text {
  text-align: left;
  padding: 0.75rem;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.expand-panel {
  position: inherit;
  height: 0rem;
  transition: height 0.3s ease-in-out;
  background: white;
  z-index: 999;
  border-radius: 0px 0px var(--border-rad) var(--border-rad);
  overflow: hidden;
}

.expand-panel.expanded {
  position: inherit;
  height: 12rem;
  transition: height 0.3s ease-in-out;
  background: white;
  z-index: 999;
  width: 100%;
  border: 1px solid;
}

.expand-panel-cb.expanded {
  height: 20rem;
}

.expand-panel-container {
  display: grid;
  grid-template-rows: 10fr 1fr;
  height: 100%;
  /* width: 37.5rem; */
}

.cb-expand-panel-container {
  display: grid;
  grid-template-rows: 12fr;
  height: 100%;
  /* width: 37.5rem; */
}

.cb-slider {
  position: relative;
  margin: auto;
  height: 2.5rem;
  background: white;
  border-radius: var(--border-rad);
  color: #000;
  transition: height 0.2s ease-in-out;

  box-shadow: .2rem .2rem .2rem .1rem rgba(0, 0, 0, 0.2);
}

.cb-grid-container {
  /* overflow: scroll; */
  overflow: hidden;
  overflow-y: scroll;
  
  display: grid;
  grid-template-rows: 11fr 1fr;
  height: calc(100% - 0.5rem);
}

.cb-container {
  align-items: center;
  display: grid;
  /* grid-template-columns: 6fr 6fr; */
  grid-template-columns: 12fr;
  /* grid-template-rows: repeat(16, 1fr); */
  grid-auto-flow: row;
  height: 100%;
  padding-top: 1rem;
  z-index: 7777;
}

.bold-und {
  font-weight: bold;
  text-decoration: underline;
}

.open-close-icon {
  position: absolute;
  right: 0;
  padding-right: 0.5rem;
}

.form-control {
  /* z-index: 10; */
}

.control-row .form-control {
  padding: 0;
  /* padding-top: 0.5rem; */
  border: none;
  /* Large Screen */
  font-size: 1.1rem;
  height: 3rem;
  line-height: 2rem;
  height: 4rem;
  height: 3.5rem;
  color: var(--primary-font-color);
  border-radius: var(--border-rad);
}

.form-select {
  font-size: 1.1rem;
}

.fa-excoll-icon {
  opacity: 0;
  transition: opacity 0.4s ease-in-out;
}

.fa-excoll-icon.expanded {
  opacity: 1;
  transition: opacity 0.4s ease-in-out;
}

.far-right {
  right: 0;
  position: absolute;
  float: right;
  padding-right: 0.5rem;
}

.summary-section {
  margin: auto;
  display: grid;
  grid-template-rows: 4fr 4fr 4fr;
  grid-template-columns: 12fr;
  border: 1px solid #090629;
  padding: 2rem 0 2rem 0;
  border-radius: var(--border-rad);
  margin-top: 1.5rem;
  max-width: 90vw;
  width: 100%;
}

.summary-section-grid {
  margin: auto;
  display: grid;
  grid-template-columns: 6fr 6fr;
  border-radius: var(--border-rad);
  margin-top: 1rem;
  max-width: 90vw;
  width: 100%;
  background: white;
  box-shadow: .2rem .2rem .2rem .1rem rgba(0, 0, 0, 0.2);
margin-top: 2rem;
}

.summ-block {
  border: 1px solid rgba(20, 20, 70, 0.4);
  padding: 1rem .5rem;
}

.summ-block.tl {
  border-radius: var(--border-rad) 0 0 0;
}

.summ-block.tr {
  border-radius: 0 var(--border-rad) 0 0;
}

.summ-block.bl {
  border-radius: 0 0 0 var(--border-rad);
}

.summ-block.br {
  border-radius: 0 0 var(--border-rad) 0;
}

.split-summary-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.summary-row {
  /* margin: auto; */
  display: grid;
  grid-template-columns: 12fr;
  padding: 0.5rem;
  font-size: xx-large;
}

.summary-row-money {
  font-size: xx-large;
  /* margin: auto; */
  display: grid;
  grid-template-columns: 12fr;
  padding: 0.5rem;
  color: #70ad47;

}

.page-footer {
  margin: auto;
  display: grid;
  grid-template-columns: 2fr 2fr 2fr 6fr;
  bottom: 1rem;
  padding: 1.5rem 0rem 0.0rem 0rem;
}

.page-footer.form-footer {
  grid-template-columns: 2fr 2fr 2fr 5fr;
}

/* 
@media (min-height: 1900px) {
  .page-footer {
  position: absolute;
  bottom: 1rem;
  width: 90vw;
  padding: 0;
  width: 100%;
  }
} */

.ninety-per {
  width: 90%;
  margin: auto;
}

.spacer {
  flex: 1;
}

.kmt-savings-button {
  line-height: 2.25rem !important;
  font-size: larger !important;
  padding-right: 1rem;
  margin-right: .5rem;
  box-shadow: .2rem .2rem .2rem .1rem rgba(0, 0, 0, 0.2);
  z-index: 3;
}

.kmt-savings-button.save {
  background: linear-gradient(to left top, rgba(2, 129, 29, 0.2) 0%, rgba(11, 129, 39, .5) 25%,  rgb(2, 129, 29) 100% /*top-left color*/);
  width: 100%;
}

.summ-hdr {
  font-size: 1.5rem;
  font-size: 1rem;
}

.summ-hdr-cnt {
  color: var(--primary-font-color);
  font-weight: bold;
  font-size: 1.5rem;
}

.summ-hdr-mny {
  color: #2e9cca;
  font-weight: bold;
  font-size: xx-large;
  font-size: 1.5rem;
}
/* 
.modal-body {
  position: relative;
  flex: 1 1 auto;
}

.modal-XXL .modal-body {
  max-height: calc(95vh);
  min-height: calc(95vh);
  background: var(--brand-color-bg);
  padding-top: 0;
  padding: 0 !important;
  overflow: hidden;
}

.modal-dialog.modal-XXL .modal-content {
  height: 1;
}

.modal-dialog.modal-XXL {
  width: 100vw;
  max-width: 100vw;
  max-height: 100vh;
  height: 100vh;
  overflow-y: hidden;
}

@media (max-width: 768px) {
  .modal-dialog.modal-XXL {
    margin: auto;
    margin-top: 1rem;
  }
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: hidden;
}

.modal-footer {
  justify-content: flex-start;
}

.modal {
  max-height: 90vh;
  min-height: 90vh;
}

.modal-body {
  max-height: calc(90vh);
  min-height: calc(90vh);
  overflow-y: auto;
} */

.hidden {
  visibility: hidden;
  height: 0;
  width: 0;
}

.modal-bg-container {
  display: grid;
  z-index: 5;
  height: 100vh;
  grid-template-rows: 1fr 10fr 1fr;
  box-sizing: border-box;
  margin: auto;
}

.form-container {
  margin: auto;
  width: 90%;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.modal-form {
  /* height: 100%; */
  margin: auto;
  /* width: 90vw; */
  display: grid;
  grid-template-columns: 4fr 6fr;
  grid-template-rows: repeat(7, 1fr);
  grid-auto-flow: row;
  row-gap: 1.5rem;
}

.frm-label {
  height: 100%;
  /* font-size: 1.5rem; */
  font-weight: bold;
}

.input-form-page {
  width: 100%;
  opacity: 1; 
  position: absolute;
  transition: opacity 0.3s linear;
  z-index: 10;
  margin: auto;
  left: 0;
  right: 0;
}

.input-form-page.clear {
  opacity: 0;
  transition: opacity 0.3s linear;
  pointer-events: none;
}



.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 7777;
}
.spinner {
  
  display: flex;
  justify-content: center;
  align-self: center;
  z-index: 8999;
  left: 0;
  right: 0;
  margin: auto;
  width: 100%;

}

.full-screen {
  align-items: center;
  height: calc(100vh);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 8999;
  /* background: pink; */
  width: 100vw;
}


.label-header {
  
  border-radius: .375rem 0 0 .375rem;
  background: linear-gradient(to left bottom, rgba(11, 146, 221, .7) 0%,  rgba(11, 146, 221, 1) 100% /*top-left color*/);
  color: white;
  /* display: flex; */
  /* font-size: larger; */
  
  width: 100%;
  margin: auto;
}

.inputform-textbox {
  height: 100%;
  border-radius:   0 .375rem .375rem 0 !important;
  border: none;
}

.disp-block {
  display: block !important;
}

.touch-label {
  color: #2e9cca;
  font-size: 1.75rem;
  padding-top: .5rem;
}