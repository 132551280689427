
.dropdown-slider {
    position: relative;
    margin: auto;
    height: 2.5rem;
    background: white;
    border-radius: var(--border-rad);
    color: #000;
    transition: height 0.2s linear;
    box-shadow: .2rem .2rem .2rem .1rem rgba(0, 0, 0, 0.2);
    padding: 0 !important;
  }

  .dropdown-row-grid {
    height: 100%;
    overflow: hidden;
    padding-top: .5rem;
    padding-left: .5rem;
    padding: .5rem;
    text-align: left;
    display: grid;
    grid-template-columns: 10fr .75rem;
  }

  .dropdown-expand-panel {
    position: inherit;
    height: 0rem;
    transition: height 0.2s linear;
    background: white;
    z-index: 999;
    border-radius: 0px 0px var(--border-rad) var(--border-rad);
    overflow: hidden;
  }

  .dropdown-expand-panel.expanded {
    position: inherit;
    height: 14rem;
    transition: height 0.3s ease-in-out;
    background: white;
    z-index: 999;
    width: 100%;
    overflow-y: scroll;
  }
  
  .dropdown-control-item {
    padding: .25rem;
    padding-left: 1rem;
    text-align: left;
  }

  .dropdown-placeholder {
    color: #cbcbcb;
  }