.slide-container {
  margin: auto;
  align-items: center;
  /* width: 60%; */
  height: 6rem;
  background: white;
  margin-top: 2rem;
  display: grid;
  grid-template-rows: 1fr 10fr 1fr;
  overflow: hidden;
  border-radius: var(--border-rad);
  /* width: 48rem; */
  /* width: 100%; */
  /* width: 70vw; */
  width: 80vw;
}

.slide-container-overlay {
  position: absolute;
  height: 4rem;
  pointer-events: none;
  background: radial-gradient(
    circle,
    rgba(255, 255, 255, 0) 18%,
    rgba(206, 206, 206, 1) 94%
  );
  z-index: 9999;
  /* width: 48rem; */
  
  width: 80vw;

  /*width: 70vw; */
}

@media only screen and (min-width: 740px) {
  .slide-container {
    max-width: 48rem;
    width: 80vw;
  }

  .slide-container-overlay {
    max-width: 48rem;
    width: 80vw;
  }
}


@media (min-height: 1900px) {
  .slide-container {
    width: 80vw;
  }

  .slide-container-overlay {
    width: 80vw;
  }
}


.slide-container-bar {
  background: rgba(128, 128, 128, 0.4);
  width: 100%;
  height: 1rem;
}

.slide-number-item {
  display: inline-block;
  font-size: x-large;
  /* padding: 0.5rem 0 .5rem 0; */
  width: 4.5rem;
  background: transparent;
  border: 0px solid transparent;
  color: rgba(68, 114, 196, 0.7);
  font-size: 1.25rem;
}

.slide-number-item.active {
  font-weight: bold;
  color: rgba(68, 114, 196, 1);
}

.slide-middle-bar {
  transition: all 0.25s linear;

  transition: all 0.5s ease-in-out;

  display: flex; /* establish flex container */
  flex-direction: row; /* default value; can be omitted */
  flex-wrap: nowrap; /* default value; can be omitted */
  justify-content: space-between; /* switched from default (flex-start, see below) */
}

.splitter {
  position: absolute;
  height: 6rem;
  width: 0.1rem;
  left: calc(50% - 2.2rem);
  background: rgba(128, 128, 128, 0.2);
  z-index: 999;
  pointer-events: none;
}

.splitterR {
  position: absolute;
  height: 6rem;
  width: 0.1rem;
  left: 50%;
  left: calc(50% + 2.2rem);
  background: rgba(128, 128, 128, 0.2);
  z-index: 999;
  pointer-events: none;
}

@media (max-width: 768px) {
  .slide-container {
    width: 30rem;
  }

  .slide-container-overlay {
    width: 30rem;
  }

  .slide-container-cb {
    width: 100%;
  }
}
