.better_cb_container_nolabel {
  margin-top: 0rem;
}

.better_cb_container {
  width: 100%;
  align-items: baseline;
  text-align: left;
  margin-left: 1.5rem;
  margin-top: .1rem;
  /* font-size: 1rem; */
  /* font-size: 1.5rem; */
  
}
.better_custom_checkbox {
  display: inline-flex;
  position: relative;
  white-space: nowrap;
}

.better_custom_checkbox > span {
  /* padding: 0.05rem 0.4rem; */
  padding: 0rem 0.5rem;
}

.better_custom_checkbox > input {
  /* height: 1.1rem;
  width: 1.1rem; */

  height: 1.25rem;
  width: 1.25rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
 
  border-radius: 10%;
  outline: none;
  transition-duration: 0.3s;
  background: #fff;
  /* background: #cecece;

background: #aaabb8 ; */

  margin-top: 0.3rem;

  /* border: 1px solid #000;
  border: 1px solid #cecece; */
  border: 1px solid  var(--brand-color-bg);
  
  border: 1px solid  var(--primary-font-color);
}

.better_custom_checkbox > input:focus {
  outline-style: solid;
  /* outline-color: #000; */
  outline-color: #cecece;
  outline-width: thin;
}

.better_custom_checkbox > input:checked {
  border: 1px solid #000;
  border: 1px solid #cecece;

  border: 1px solid  var(--brand-color-bg);
  border: 1px solid  var(--primary-font-color);
  /* background: rgba(13, 110, 253, 0.75); */
  background: var(--brand-color-foreRGBA);

  color: var(--brand-color-bg);

  background: #103453;

  background-color: var(--color04);

  border-radius: 10%;

}

/* This is the actual check */
.better_custom_checkbox > input:checked + span::before {
  content: "\2713";
  display: block;
  text-align: center;
  position: absolute;
  left: .17rem;
  top: -.07rem;
  font-weight: bold;
  /* color: #fff; */
  color: var(--brand-color-bg);


  color: var(--brand-color-bg);

  color: #aa8802;

  color: #fff;
}


.modal-form .better_cb_container {
  margin-left: 0;
  margin-top: 0;
}
.modal-form .better_custom_checkbox > input:checked + span::before {
  left: .25rem;
  top: .175rem;
}


.modal-form .better_custom_checkbox > span {
  /* padding: 0.05rem 0.4rem; */
  padding: .15rem 0.3rem;
}
