.pascal-hdr-cont {
    height: 26vh;
    margin: auto;
    overflow: hidden;
    width: 100%;
    display: flex;
    margin-bottom: 1.5rem;
}

.pascal-hdr-inner {
    display: grid;
    height: 100%;
    grid-template-columns: 4fr 6fr;
    margin: auto;
    width: 100%;
    margin-top: 0;
    
}
.pascal-anagram {
    margin: auto;
    margin-left: 0;
}

.pascal-hdr-logo-cont {
    height: 100%;
    height: 20vh;
    display: grid;
    grid-template-rows: 6fr 6fr;
    margin-left: auto;
    
}
.pascal-hdr-logo {
    height: 20vh;
    margin: auto;
}

.pascal-hdr-textblock {
    font-size: 2rem;
    text-align: left;
    color: #fff;
}

.pascal-hdr-textblock .accent { 
    font-weight: bold;
    color: var(--primary-font-color);
}


.pascal-hdr-logo-cont .text-tri-block {
    margin-top: .25rem;
    display: grid;
    row-gap: 0;
    margin-left: auto;
    height: 100%;
  }
  
  .pascal-hdr-logo-cont .text-block {
    display: flex;
    color: white;
    font-size: 1rem;
    align-items: center;
    margin: auto;
    padding: 0;
  }
