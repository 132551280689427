
.splash-screen-page {
  width: 100vw;
  height: 100vh;
  display: grid;
  grid-template-rows: 17vh 58vh 10vh 1fr;
  opacity: 1;
  top: 0;
  left: 0;
  position: absolute;
  transition: opacity 0.3s linear;
  z-index: 10;

  background: linear-gradient(
      to right bottom,
      rgba(79, 75, 81, 0.2) 0%,
      rgba(79, 75, 81, 0.5) 10% /*middle color*/,
      rgba(79, 75, 81, 1) 100% /*top-left color*/
    ),
    linear-gradient(rgba(0, 0, 0, 1), rgba(0, 0, 0, 1));
}

.splash-screen-page.clear {
  opacity: 0;
  transition: opacity 0.3s linear;
  pointer-events: none;
}

.text-tri-block {
  display: grid;
  row-gap: 0;
}

.text-block {
  display: flex;
  color: white;
  font-size: 3rem;
  /* font-size: larger; */
  align-items: center;
  margin: auto;
  padding-top: .1rem;
}

.text-sm {
  font-size: 1.5rem;
}

.pascal-logo {
  height: 60vh;
  right: 0;
  padding: 0;
  padding-top: 1rem;
}
